import React, { useState } from "react";
import bgImage from "../assets/loginBg.jpeg";
import Lottie from "react-lottie";
import lottieBall from "../assets/Animation - 1713942471532.json";
import axios from "axios";
import OtpInput from "react-otp-input";
import { Navigate, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginSub = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState(false);
  const [otpText, setOtpText] = useState("");
  const [selectedPack, setSelectedPack] = useState("Daily");
  const [msisdnNumber, setMsisdnNumber] = useState("");
  const [isloading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [q] = useSearchParams();
  const navigate = useNavigate();
  
  const handleInputChange = (e) => {
    setMsisdnNumber(e.target.value);
  };

  const handlePackChange = (event) => {
    setSelectedPack(event.target.value); // Update selected pack when radio button changes
  };

  const getotp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("https://uvcannsl.com/api/otp", {
        msisdn: msisdnNumber,
        ext_ref: q.get("ext_ref"),
      });
      localStorage.setItem("ani",msisdnNumber)
      setLoading(false);
      console.log(await response.data, "========");
      if(response.data.result === 2){
        return navigate("/home")
      }
      if (response.data.result === 1) {
        const location = response.data.location;
        localStorage.setItem("location", location);
        setOtp(true);
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error("Network Error");
    }
  };

  const confirmOtp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post("/api/otp/confirm", {
        msisdn: msisdnNumber,
        otp: otpText,
        ext_ref: q.get("ext_ref"),
        location: localStorage.getItem("location"),
        pack_type: selectedPack,
      });
      setLoading(false);
      console.log(response.data, "==----00-0");
      if (response.data.result === 2) {
        return navigate("/home");
      }
      if (response.data.result === 1) {
        // success
        toast.success(response.data.msg);
        setModal(true);
        setTimeout(() => {
          navigate("/home");
        }, 4000);
      } else {
        setErrorMessage("Invalid OTP or OTP Expired");
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error("Network Error");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex items-center justify-center  w-full h-[90vh]">
  <div className="absolute inset-0 w-full">
          <img
            src={bgImage}
            alt=""
            className="absolute inset-0 object-cover w-full h-full z-0"
          />
          <div className="absolute inset-0 bg-black opacity-60"></div>
        </div>

        <div className="w-full max-w-sm h-[600px] p-3 z-10 top-20">
          {/* <header className="w-20 mx-auto mb-5">
            <Lottie
              options={{
                animationData: lottieBall,
              }}
              style={{ width: "90%", height: "auto" }}
            />
          </header> */}
          {!modal ? (
            <form className=" py-7" >
              <div className="text-5xl p-8 text-center text-red-600  flex font-bold ml-2 py-3 top-0">
                UV <span className="text-white">GAMES</span>
              </div>
              <div className="flex flex-col">

                <div className="mt-9">
                  {otp ? (
                    <div className="text-center justify-center  items-center sm:ml-0">
                      <div className="text-white font-semibold text-xl p-6">
                        Enter OTP
                        </div>
                      <OtpInput
                        value={otpText}
                        onChange={setOtpText}
                        numInputs={4}
                        separator={<span>-</span>}
                        inputStyle={{
                          width: "40px",
                          height: "40px",
                          fontSize: "20px",
                          textAlign: "center",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          marginRight: "5px",
                          fontWeight: "700",
                          color: "#FF0000",
                        }}
                        containerStyle={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                      
                        }}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="">
                        <div className="relative bg-inherit text-center">
                          <input
                            type="number"
                            onChange={handleInputChange}
                            value={msisdnNumber}
                            id="username"
                            name="username"
                            className="peer bg-transparent h-14 w-[310px] sm:h-12 sm:w-[350px] text-gray-100 placeholder-transparent ring-1 px-2 ring-white focus:ring-red-600 focus:outline-none focus:border-rose-600 [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield]"
                            placeholder={otp ? "Enter Received OTP" : "Enter Mobile Number Below"}
                          />
                          <label
                            htmlFor="username"
                            className="absolute cursor-text left-12 -top-6 text-sm text-gray-10 bg-inherit mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-300 peer-placeholder-shown:top-3 peer-focus:-top-5 peer-focus:text-white-600 peer-focus:text-sm transition-all text-center"
                          >
                            {otp
                              ? "Enter OTP"
                              : "Enter Mobile Number Below"}
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div>
                  {!otp && (
                    <>
                    <div className=" ">

                    <div className="py-4 text-center ">
                        <select
                          className="block  w-[310px]  sm:w-[350px] cursor-pointer -none  bg-black/10 border border-gray-900 text-red-500 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-black/40 items-center text-center"
                          value={selectedPack}
                          onChange={handlePackChange}
                        >
                          <option className="h-9 text-m bg-black" value="Daily">
                            <div className="p-6">Daily pack @3 leones</div>
                          </option>
                          <option className="h-6 text-m bg-black" value="Weekly">Weekly pack @11 leones</option>
                        </select>
                      </div>

                    </div>
                      
                    </>
                  )}
                </div>

              </div>

              <div className="mx-auto flex items-center justify-center gap-10 px-6 py-5 cursor-pointer">
                <ul className="border-green-600 hover:border-red-400 hover:bg-red-700 group flex-1 -skew-x-[30deg] transform border">
                  <li className="m-4 skew-x-[30deg] transform bg-transparent px-9 text-center first-letter:uppercase">
                    <b
                      className="block text-lg group-hover:text-white cursor-pointer"
                      disabled={isloading ? true : false}
                      onClick={otp ? confirmOtp : getotp}
                    >
                      {isloading ? "loading......" : "Subscribe"}
                    </b>
                  </li>
                </ul>
              </div>
            </form>
          ) : (
            <div className="py-5 md:p-24 mt-10">
              <h1 className="text-lg text-white">
                Dear customer you have successfully subscribed to Wheel of
                Fortune, you will be redirected to content
              </h1>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginSub;
