import React, { useState } from "react";
import bgImage from "../assets/loginBg.jpeg";
import Lottie from "react-lottie";
import lottieBall from "../assets/Animation - 1713942471532.json";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HeFlow = () => {
  const [selectedPack, setSelectedPack] = useState(""); // State to track selected pack
  const [msisdnNumber, setMsisdnNumber] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [q] = useSearchParams();
  const navigate = useNavigate();

  const handlePackChange = (event) => {
    setSelectedPack(event.target.value);
  };

  const handleSubscribe = async () => {
    if (!selectedPack) {
      toast.error("Please select a pack (Daily or Weekly).");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post("/api/sub-user", {
        msisdn:q.get("msisdn"),
        // otp: otpText,
        ext_ref: q.get("ext_ref"),
        // location: localStorage.getItem("location"),
        pack_type: selectedPack,
        m_act: "web",
        language:"en"
      });

      setLoading(false);
      if (response.data.result === 1) {
        setModal(true);
        setTimeout(() => {
          navigate("/home");
        }, 4000);
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error("Network Error");
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex items-center justify-center min-h-screen bg-purple-700 relative">
        <div className="absolute inset-0">
          <img
            src={bgImage}
            alt=""
            className="absolute inset-0 object-cover w-full h-full z-0"
          />
          <div className="absolute inset-0 bg-black opacity-60"></div>
        </div>

        <div className="w-full max-w-sm  p-5 z-10">
          {!modal ? (
            <form>
              <div className="text-3xl p-3 text-center text-red-600 font-bold">
                UV <span className="text-white">GAMES</span>
              </div>

              <div className="py-4">
                <select
                  className="block cursor-pointer -none w-full bg-black/10 border border-gray-900 text-red-500 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-black/40"
                  value={selectedPack}
                  onChange={handlePackChange}
                >
                  <option className="h-9 text-m bg-black" value="Daily" key="daily">
                    <div className="p-6">Daily pack @3 leones</div>
                  </option>
                  <option className="h-6 text-m bg-black" value="Weekly" key="weekly">
                    Weekly pack @11 leones
                  </option>
                </select>
              </div>

              <div className="mx-auto flex items-center justify-center gap-10 px-6 py-5 cursor-pointer">
                <ul className="border-green-600 hover:border-red-400 hover:bg-red-700 group flex-1 -skew-x-[30deg] transform border">
                  <li className="m-4 skew-x-[30deg] transform bg-transparent px-9 text-center first-letter:uppercase">
                    <b
                      className="block text-lg group-hover:text-white cursor-pointer"
                      disabled={!selectedPack || isLoading}
                      onClick={handleSubscribe}
                    >
                      {isLoading ? "loading......" : "Subscribe"}
                    </b>
                  </li>
                </ul>
              </div>
            </form>
          ) : (
            <div>
              <h1 className="text-lg mt-">
                Dear customer, you have successfully subscribed to UV GAMES. You will be redirected to content.
              </h1>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HeFlow;
