import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import PageNotFound from "../Pages/PageNotFound";
import SeeAll from "../Pages/SeeAll";
import ErrorPage from "../Pages/Error";
import Login from "../Pages/Login";
import LoginSub from "../Pages/LoginSub"
import HeFlow from "../Pages/HeFlow";

const Routing=()=>{
    return(<>
        <BrowserRouter>
            <Routes>
                <Route path="/Home" exact={true} element={<Home/>}></Route>
                <Route path="/login" exact={true} element={<Login/>}></Route>
                <Route path="/seeAll" exact={true} element={<SeeAll/>}></Route>
                <Route path="*" exact={true} element={<PageNotFound/>}></Route>
                <Route path="/error" exact={true} element={<ErrorPage/>}></Route>
                <Route path="/pin" exact={true} element={<LoginSub/>}></Route>
                <Route path="/" exact={true} element={<HeFlow/>}></Route>
            </Routes>
        </BrowserRouter>
    </>)
}
export default Routing;